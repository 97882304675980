import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import AudioAlarmTable from 'components/Web_User_Interface/720p_Series/Alarm/Audio_Alarm/audioAlarmTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Audio Alarm",
  "path": "/Web_User_Interface/720p_Series/Alarm/Audio_Alarm/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "The camera's Audio Alarm uses the integrated microphone to detect noises and trigger alarm events. These events are handled just like motion detection events and will trigger the activated Alarm Actions. Please be aware that the sensitivity of the detection is both influenced by the sensitivity slider here, as well as by the overall volume of the microphone signal.",
  "image": "./WebUI_720p_SearchThumb_Alarm_Audio_Alarm.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Alarm_Audio_Alarm.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Audio Alarm' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='The camera`s Audio Alarm uses the integrated microphone to detect noises and trigger alarm events. These events are handled just like motion detection events and will trigger the activated Alarm Actions. Please be aware that the sensitivity of the detection is both influenced by the sensitivity slider here, as well as by the overall volume of the microphone signal.' image='/images/Search/WebUI_720p_SearchThumb_Alarm_Audio_Alarm.png' twitter='/images/Search/WebUI_720p_SearchThumb_Alarm_Audio_Alarm.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Alarm/Audio_Alarm/' locationFR='/fr/Web_User_Interface/720p_Series/Alarm/Audio_Alarm/' crumbLabel="Audio Alarm" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "audio-alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#audio-alarm",
        "aria-label": "audio alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Audio Alarm`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`The camera’s Audio Alarm uses the integrated microphone to detect noises and trigger alarm events. These events are handled just like motion detection events and will trigger the activated Alarm Actions. Please be aware that the sensitivity of the detection is both influenced by the sensitivity slider here, as well as by the overall volume of the microphone signal.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0acc03fd0adfddaee85e945d50e6f5f3/6bbf7/WebUI-Alarm_Audio.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAACvUlEQVQ4y6VU204aURTlt5r2N2rqh/gP9ZkYZVQsF+1DEWyMERjkfhGGi8wAFoaLyswgKAjG+Lqavc1MwFRr68PKPmdnn3X23XZw8BOfl5bw4eMnfFlehtPpxM7ODnZ3d/m8vb3Nd6/XC5frGzweLwRBgMPhgMvlYjuP2w1BcEAQNmE7OjrC+vo6vq6uMkEsFmPE43GW0WiUZSKRYB3ro1FLn0qlcHp6ar2zfd/b49/9fj9OTk5QqVTQaDRQr9dRbzRwfn6OWq2GarUKWZYZiqKwJJtsNgufz4dyucyweTwe2O12dj0UCkFVW+j1elDbbfS6XTSbv6AbBqbTKW5vbzEej1kSZrMZWq0WVlZW2DsmJO82Njbg9wcQDoehqioUWYYkFVEpl1EoFFCtyuj3+xgOh7i+vmbQeTAYsKQ0JJPJJ0LybGtrC/v7+zg+PsbFxQW0fh/NZgvtdgedTofJDMPAZDJhD+dxc3ODSCTCuXwKmSskcDXJSyIkAk3TYRg6E93d3THmiYh8NBpB0zTOvUUYCATg8/1APp9ntyl/ZGiGZYam60/kdDZBOrJbINQ1DZeXl1ay6UeqcqlU4oqboLahR2dzOrKhnFMLWTkkQrXZwmw6w3gy4XCJnLyk/Jh4fjd1FPqCh6PhEHpfx+PjIx4eHnB1dcUtQoZvAbXOQlFKkoRELM7tISsK5+VP1XwJ9PkCoRgOY82+hk1hE8FgkJNsEr7m2YuEdBHFMCe9UJC4ymT0/4RiBLFojKckGAxxxd9FWFNqPPyEYrH4fsJMNsMjR4shl8sx4fOp+KeipNNpiKLIyGQy3IfUCua4/Q339/fc2BZhIZ9n76g5aVF2u11r3N4Cam6K0CKkMImM9hnl8PDwkNc7LQvaQq+BbGixuN1uSJLE7200j6lkEtlsjmeTlPQTnd8Ksqd3RPobmLyrdEAeYG0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0acc03fd0adfddaee85e945d50e6f5f3/e4706/WebUI-Alarm_Audio.avif 230w", "/en/static/0acc03fd0adfddaee85e945d50e6f5f3/d1af7/WebUI-Alarm_Audio.avif 460w", "/en/static/0acc03fd0adfddaee85e945d50e6f5f3/04ac8/WebUI-Alarm_Audio.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0acc03fd0adfddaee85e945d50e6f5f3/a0b58/WebUI-Alarm_Audio.webp 230w", "/en/static/0acc03fd0adfddaee85e945d50e6f5f3/bc10c/WebUI-Alarm_Audio.webp 460w", "/en/static/0acc03fd0adfddaee85e945d50e6f5f3/d8378/WebUI-Alarm_Audio.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0acc03fd0adfddaee85e945d50e6f5f3/81c8e/WebUI-Alarm_Audio.png 230w", "/en/static/0acc03fd0adfddaee85e945d50e6f5f3/08a84/WebUI-Alarm_Audio.png 460w", "/en/static/0acc03fd0adfddaee85e945d50e6f5f3/6bbf7/WebUI-Alarm_Audio.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0acc03fd0adfddaee85e945d50e6f5f3/6bbf7/WebUI-Alarm_Audio.png",
              "alt": "Web User Interface - 720p Series - Alarm Audio Alarm",
              "title": "Web User Interface - 720p Series - Alarm Audio Alarm",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <AudioAlarmTable mdxType="AudioAlarmTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "use-your-cameras-microphone-to-detect-noises",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-your-cameras-microphone-to-detect-noises",
        "aria-label": "use your cameras microphone to detect noises permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use your camera´s microphone to detect noises`}</h3>
    <p>{`The camera's Audio Alarm uses the integrated microphone to detect noises and trigger alarm events. These events are handled just like motion detection events and will trigger the activated `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Actions/"
      }}>{`Alarm Actions`}</a>{`. Please be aware that the sensitivity of the detection is both influenced by the sensitivity slider here, as well as by the overall volume of the microphone signal. You can adjust the amplification of the signal in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Multimedia/Audio/"
      }}>{`Multimedia Section`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      